import * as React from "react";
import { PageProps } from "gatsby";
import * as styles from "./styles.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Breadcrumb } from "../../../components/breadcrumb/breadcrumb";
import Layout from "../../../components/layout/layout";
import Container from "../../../components/container/container";
import Heading from "../../../components/basic-components/heading/heading";
import { ProductBar } from "../../../components/product-bar/product-bar";
import { Level } from "react-accessible-headings";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Helmet } from "react-helmet";
import blogImage from "../../../images/cozum/bogaz-agrisi-nedir/Content-image.webp";
import { useEffect } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const CoughPage: React.FC<PageProps> = ({ location }: PageProps) => {
  useEffect(() => {
    if (location.hash) {
      scrollTo(location.hash);
    }
  }, []);
  return (
    <Layout
      stickyHeader={false}
      seoConfig={{
        title: "Boğaz Ağrısı Nedir, Nasıl Geçer?",
        description:
          "Boğaz ağrısı nedir, nasıl geçer? Boğaz ağrısına ne iyi gelir? gibi soruların cevapları için sayfamızı ziyaret edin!",
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Öksürük","item": "https://www.bisolnatur.com.tr/oksuruk/"},
                  {"@type": "ListItem","position": 3,"name": "Boğaz Ağrısı Nedir, Nasıl Geçer?","item": "${location.href}"}
                ]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${location.href}"
              },
              "headline": "Öksürük Nedir?",
              "image": "${location.origin + blogImage}",  
              "author": {
                "@type": "Organization",
                "name": "Boğaz Ağrısı Nedir, Nasıl Geçer?",
                "url": "${location.origin}"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${location.origin}/logo.svg"
                }
              },
              "datePublished": "2021-11-10",
              "dateModified": "2021-11-10"
            }
          `}
        </script>
        <script type="application/ld+json">{`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Boğaz Ağrısı Nedir?","acceptedAnswer":{"@type":"Answer","text":"Boğaz ağrısı, bademcikler, ses telleri ve genzin (ağız boşluğunun hemen arkasındaki kısım) bulunduğu bölgedeki dokuların inflamasyonu (iltihaplanması) sonucu oluşan bir şikayettir. Boğaz ağrısının gelişmesi ile birlikte bireyler, besinleri tüketirken yutkunma sırasında veya konuşurken bu şikayetin varlığından yakınır. Tahriş hissi ile birlikte seyreden boğaz ağrısı, bazı durumlarda yutkunma sırasında daha belirgin hale gelebilir."}},{"@type":"Question","name":"Boğaz Ağrısının Nedenleri Nelerdir?","acceptedAnswer":{"@type":"Answer","text":"Boğaz ağrısı şikayetinin oluşmasında en sık olarak virüsler karşımıza çıkar. Rhinovirus (nezle virüsü), influenza (grip virüsü), adenovirüs, koronavirüs ve parainfluenza virüsü, boğaz ağrısı şikayetine neden olabilen başlıca viral patojenlerdir. Boğaz ağrısının bir diğer sık karşılaşılan nedeni olan bakteriler; özellikle çocukların neredeyse yarısında, bu şikayetin altında yatan neden olarak karşımıza çıkar."}},{"@type":"Question","name":"Boğaz Ağrısı Nasıl Geçer, Ne İyi Gelir?","acceptedAnswer":{"@type":"Answer","text":"Tuzlu su ile yapılan gargara, sekresyonların atılımını kolaylaştırarak bu şikayetin hafifletilmesinde etkili olabilecek bir yaklaşımdır. Bir bardak ılık suyun içerisine yarım çay kaşığı tuz eklenerek oluşturulan solüsyon, gargara için kullanılabilir. Hava kuruluğu kaynaklı olarak oluşan boğaz ağrılarında yaşanılan ortamın nemlendirici cihazlar vasıtasıyla nemlendirilmesi, hem oluşan şikayetin hafifletilmesine hem de bu nedenle boğaz ağrısının tekrar meydana gelmesine karşı atılabilecek bir adımdır."}}]}`}</script>
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth>
        <div className={classNames(styles.header)}>
          <StaticImage
            className={styles.image}
            src="../../../images/cozum/bogaz-agrisi-nedir/Content-image.webp"
            alt="Boğaz ağrısı yaşayan kadın görseli"
            width={1680}
            height={450}
          />
          <Container>
            <Heading>Boğaz Ağrısı Nedir, Nasıl<br />Geçer?</Heading>
            <ul>
              <li>
                <AnchorLink
                  className="blue"
                  to="#bogaz-agrisi-nedir"
                  title="Boğaz Ağrısı Nedir?"
                >
                  Boğaz Ağrısı Nedir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#bogaz-agrisinin-nedenleri-nelerdir"
                  title="Boğaz Ağrısının Nedenleri Nelerdir? "
                >
                  Boğaz Ağrısının Nedenleri Nelerdir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#bogaz-agrisinin-belirtileri-nelerdir-teshisi-nasil-konulur"
                  title="Boğaz Ağrısının Belirtileri Nelerdir, Teşhisi Nasıl Konulur?  "
                >
                  Boğaz Ağrısının Belirtileri Nelerdir, Teşhisi Nasıl Konulur?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#bogaz-agrisi-nasil-gecer-ne-iyi-gelir"
                  title="Boğaz Ağrısı Nasıl Geçer, Ne İyi Gelir? "
                >
                  Boğaz Ağrısı Nasıl Geçer, Ne İyi Gelir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#cocuklarda-bogaz-agrisina-ne-iyi-gelir"
                  title="Çocuklarda Boğaz Ağrısına Ne İyi Gelir? "
                >
                  Çocuklarda Boğaz Ağrısına Ne İyi Gelir?
                </AnchorLink>
              </li>
            </ul>
          </Container>
        </div>
      </Container>
      <Container>
        <div className={styles.holder}>
          <p id="bogaz-agrisi-nedir" className="extra-bold">
            Boğaz ağrısı herkesin yaşamının bir bölümünde karşılaştığı bir
            problemdir. Kaşıntı, yanma, boğaza bir şey takılmış hissi, boğaz
            ağrısı şikayetleri arasında yer alır. Boğazda ağrı hissi, sağlık
            kuruluşlarına en sık başvuru nedenlerinin başında gelen bir
            semptomdur. Her yıl milyonlarca insan boğaz ağrısı şikayeti ile
            doktorlara başvurur. Genel olarak bahsedilirse virüsler, bakteriler
            ve hava kuruluğu gibi çevresel faktörler, boğaz ağrısının en sık
            nedenleri arasındadır. Boğaz ağrısı her ne kadar rahatsız edici bir
            şikayet olsa da genellikle bir süre sonra kendiliğinden gerileme
            eğiliminde olan bir sağlık problemdir.
          </p>
          <section className={styles.whatHolder}>
            <Level>
              <h2 className="orange underline">Boğaz Ağrısı Nedir? </h2>
              <p>
                Boğaz ağrısı, bademcikler, ses telleri ve genzin (ağız
                boşluğunun hemen arkasındaki kısım) bulunduğu bölgedeki
                dokuların inflamasyonu (iltihaplanması) sonucu oluşan bir
                şikayettir. Boğaz ağrısının gelişmesi ile birlikte bireyler,
                besinleri tüketirken yutkunma sırasında veya konuşurken bu
                şikayetin varlığından yakınır. Tahriş hissi ile birlikte
                seyreden boğaz ağrısı, bazı durumlarda yutkunma sırasında daha
                belirgin hale gelebilir.
              </p>
              <Level>
                <Heading id="bogaz-agrisinin-nedenleri-nelerdir">
                  Boğaz Ağrısının Nedenleri Nelerdir?
                </Heading>
              </Level>
              <p>
                Boğaz ağrısı şikayetinin oluşmasında en sık olarak virüsler
                karşımıza çıkar. Rhinovirus (nezle virüsü), influenza (grip
                virüsü), adenovirüs, koronavirüs ve parainfluenza virüsü, boğaz
                ağrısı şikayetine neden olabilen başlıca viral patojenlerdir.
                Boğaz ağrısının bir diğer sık karşılaşılan nedeni olan
                bakteriler; özellikle çocukların neredeyse yarısında, bu
                şikayetin altında yatan neden olarak karşımıza çıkar.
                Streptococcus türü bakteriler nedeniyle oluşan boğaz ağrısında
                yüksek ateş, boğazda beyaz yama şeklinde lezyonlar ve boyun
                bölgesinde şiş ve ağrılı bezler gibi diğer şikayetler de boğaz
                ağrısına eşlik edebilir. Mikroplar nedeniyle oluşan boğaz
                ağrısına karşı atılacak en önemli adımlardan birinin el
                hijyenine dikkat edilmesi olduğu unutulmamalıdır. Su ve sabuna
                ulaşımın olmadığı hallerde ise el dezenfektanları kullanılarak
                gerekli hijyen sağlanabilir. Bu temizlik uygulamaları özellikle
                el sıkışma, kapı kolu veya klavye gibi ortak eşyalara dokunma
                sonrasında tekrarlanmalıdır.
                <br />
                <br />
                Polen, çimen veya hayvan tüyü gibi çeşitli maddelerin kimyasal
                yapısı bazı insanlarda bağışıklık sisteminin aşırı bir
                aktivasyon göstermesine neden olarak, boğazda tahriş ve ağrıya
                neden olabilir. Boğaz ağrısı dışında burun akıntısı, gözlerde
                sulanma ve hapşırma, alerjik kökenli boğaz ağrılarında
                oluşabilecek diğer belirtiler arasındadır. Alerjik durumlarda
                burun içerisinde aşırı miktarda üretilen sıvı, genze doğru
                akarak boğaz üzerine damlayabilir. Post nazal akıntı olarak
                isimlendirilen bu durum, boğazı tahriş ederek boğaz ağrısı
                şikayeti gelişimi ile sonuçlanır.
              </p>
              <StaticImage
                className={styles.image}
                src="../../../images/cozum/bogaz-agrisi-nedir/sick-young-woman-sitting-in-bed-and-having-cough-w-2021-08-29-22-51-33-utc.webp"
                alt="Öksüren görseli"
                width={792}
                height={430}
              />
              <p>
                Çevresel kaynaklı maruz kalınan bazı kimyasallar, boğaz
                bölgesindeki mukozayı tahriş ederek boğaz ağrısı şikayetinin
                oluşmasına zemin hazırlayabilir. Hava kirliliği, sigara dumanı
                ve çeşitli temizlik kimyasallarına maruz kalınması, boğaz ağrısı
                ile sonuçlanabileceği için dikkatli olunmalıdır.
                <br />
                <br />
                Hava kuruluğu, burun, ağız ve boğaz bölgesindeki dokularda nem
                kaybına neden olarak bu yapıların kuruması ile sonuçlanabilir.
                Kuruyan dokular zaman içerisinde tahriş olarak boğaz ağrısı
                şikayetinin gelişmesine neden olabilir. Hava kuruluğuna
                maruziyet, özellikle soğuk kış aylarında soğuk ve kuru hava veya
                kapalı ortamlarda kullanılan ısıtıcılar nedeniyle meydana gelir.
                <br />
                <br />
                Bir diğer boğaz ağrısı nedeni de bu bölgede meydana gelen
                travmalardır. Direkt olarak boyuna alınan travmalara ek olarak
                sert gıdaların boğazda kalması, bu bölgenin tahriş olmasına ve
                boğaz ağrısı şikayetinin gelişmesine neden olabilir. Ses
                tellerinin ve boğaz bölgesindeki kasların aşırı kullanılması da
                boğaz ağrısı nedenleri arasında yer alır. Özellikle fitness
                antrenörlüğü veya öğretmenlik gibi meslek mensupları sürekli
                olarak yüksek ses tonu ile konuşmak zorunda olan iş gruplarıdır.
                Bu bireylerde gerilen ses telleri ve kaslar yeterince
                dinlendirilmediğinde boğaz ağrısı şikayetine neden olabilirler.
              </p>
            </Level>
          </section>
          <section
            id="bogaz-agrisinin-belirtileri-nelerdir-teshisi-nasil-konulur"
            className={styles.typesHolder}
          >
            <Level>
              <Level>
                <Heading>
                  Boğaz Ağrısının Belirtileri Nelerdir, Teşhisi Nasıl Konulur?{" "}
                </Heading>
              </Level>
              <p>
                Boğaz ağrısı şikayeti olan çoğu bireyde çeşitli belirtilerin de
                eşlik ettiği görülür. Boğaz ağrısı bu durumun altında yatan
                nedene bağlı olarak sürekli veya sadece yutkunma sırasında
                ortaya çıkabilir. Boğaz ağrısı ile birlikte oluşabilecek
                belirtiler şu şekilde özetlenebilir:
              </p>
              <ul>
                <li className="blue">
                  Konuşma veya yutkunma sırasında boğaz ağrısının şiddetinde
                  artış meydana gelmesi,
                </li>
                <li className="blue">
                  Boyun ve çene bölgesindeki bezlerde şişme,
                </li>
                <li className="blue">
                  Bademciklerin kızarık görünmesi ve şişmesi,
                </li>
                <li className="blue">
                  Bademcikler üzerinde beyaz renkli lekelenmeler oluşması,
                </li>
                <li className="blue">Ses kısıklığı veya seste kabalaşma.</li>
              </ul>
              <p>
                Bademcikler üzerinde oluşan beyaz lezyonlar, boğaz ağrısının
                nedeni hakkında fikir vermesi açısından önem arz eder. Sadece
                şiş ve kırmızı görünen bademcikler virüslere bağlı oluşan boğaz
                ağrısına işaret ederken; üzerinde beyaz lezyonlar olan
                bademcikler, altta yatan nedenin bakteriler olabileceğini
                düşündürür. Bu belirtiler dışında boğaz ağrısına diğer çeşitli
                şikayetler de eşlik edebilir:
              </p>
              <ul>
                <li className="blue">Burun tıkanıklığı veya burun akıntısı,</li>
                <li className="blue">Hapşırma,</li>
                <li className="blue">Öksürük,</li>
                <li className="blue">Ateş,</li>
                <li className="blue">Üşüme, titreme,</li>
                <li className="blue">
                  Vücudun çeşitli bölgelerinde ağrı hissedilmesi,
                </li>
                <li className="blue">Baş ağrısı,</li>
                <li className="blue">İştah kaybı.</li>
              </ul>
              <StaticImage
                className={styles.image}
                src="../../../images/cozum/bogaz-agrisi-nedir/woman-doctor-examining-throat-of-little-girl-with-2021-08-29-10-47-36-utc.webp"
                alt="Muayene olan çocuk görseli"
                width={792}
                height={430}
              />
              <p>
                Birçok boğaz ağrısı şikayeti genellikle 5-10 gün içerisinde
                gerileme gösterir. Sık olarak boğaz ağrısı yaşama konusunda risk
                grubunda yer alan kişiler şu şekilde özetlenebilir:
              </p>
              <ul>
                <li className="blue">5-15 yaş arasındaki çocuklar,</li>
                <li className="blue">Çeşitli alerjileri bulunan bireyler,</li>
                <li className="blue">
                  Bağışıklık sistemi çeşitli nedenlerle zayıflamış olan kişiler,
                </li>
                <li className="blue">
                  Çeşitli kimyasal irritanlara maruz kalmış bireyler,
                </li>
                <li className="blue">
                  Bademcikleri anatomik olarak normalden büyük veya farklı
                  şekilde olan kişiler.
                </li>
              </ul>
              <p>
                Boğaz ağrısı tanısı, mevcut belirtiler ışığında rutin fizik
                muayene ile koyulabilir. Küçük bir ışık kaynağı vasıtası ile
                boğaz ve arkası incelendiğinde, kızarıklık ve ödem gibi diğer
                belirtiler de tespit edilebilir. Aynı zamanda boyun muayenesi
                ile şişen lenf bezlerinin de ortaya çıkarılması sağlanabilir.
                <br /> <br />
                Doktorunuzun bilgisi ve önerisi dahilinde yapabileceğiniz
                çeşitli uygulamalar, bu şikayetin hafifletilmesinde etkili
                olabilir. Ancak bazı durumlarda medikal müdahale ihtiyacı söz
                konusudur. Özellikle kalıcı ve kendiliğinden gerileme
                göstermeyen boğaz ağrılarında, kulak ağrısı, ciltte döküntü,
                kanlı balgam veya yüksek ateş gibi ek semptomların varlığında,
                sağlık kuruluşlarına başvurarak uzman hekimden destek alınması
                önerilir.
              </p>
            </Level>
          </section>
          <section
            id="bogaz-agrisi-nasil-gecer-ne-iyi-gelir"
            className={styles.whyHolder}
          >
            <Level>
              <Level>
                <Heading>Boğaz Ağrısı Nasıl Geçer, Ne İyi Gelir? </Heading>
              </Level>
              <p>
                Boğazda tahriş, kuruluk ve kaşıntı şeklinde oluşan boğaz ağrısı,
                bireylerin yaşam kalitesini olumsuz yönde etkileyebilen bir
                şikayettir. Bu şikayetin hafifletilmesi, kişinin gün içerisinde
                daha aktif olmasına ve uyku kalitesinin iyileştirilmesine kadar
                oldukça etkili bir geri dönüş sağlayabilir.
                <br /> <br />
                Boğaz ağrısı şikayetine genellikle burun, sinüsler ve boğaz
                bölgesinde salgı artışı da eşlik eder. Tuzlu su ile yapılan
                gargara, sekresyonların atılımını kolaylaştırarak bu şikayetin
                hafifletilmesinde etkili olabilecek bir yaklaşımdır. Bir bardak
                ılık suyun içerisine yarım çay kaşığı tuz eklenerek oluşturulan
                solüsyon, gargara için kullanılabilir. Hava kuruluğu kaynaklı
                olarak oluşan boğaz ağrılarında yaşanılan ortamın nemlendirici
                cihazlar vasıtasıyla nemlendirilmesi, hem oluşan şikayetin
                hafifletilmesine hem de bu nedenle boğaz ağrısının tekrar
                meydana gelmesine karşı atılabilecek bir adımdır. Hava
                nemlendirici cihazları bulunmayan kişiler, sıcak su vasıtası ile
                nemlendirilmiş havada nefes alarak boğaz ağrısı şikayetlerinin
                hafifletilmesine katkı sağlayabilirler.
                <br /> <br />
                Boğaz ağrısı ve geniz akıntısı varlığında bireyler, yatarken
                rahat nefes alıp verme konusunda zorluk yaşayabilir. Yatarken
                başın altına koyulacak fazladan bir yastık, başın daha yüksekte
                olmasını sağlayarak geniz bölgesindeki akıntının rahatlamasına
                yardımcı olabilir. Böylelikle ağızdan nefes alıp verme yerine
                solunum için burun kullanılır. Böylece ağız açık şekilde uyuma
                sonucu boğaz bölgesinde meydana gelebilecek kuruluğun önüne
                geçilmesi sağlanabilir.
              </p>
              <StaticImage
                className={styles.image}
                src="../../../images/cozum/bogaz-agrisi-nedir/drinking-water-2021-08-26-20-09-55-utc.webp"
                alt="Hapşuran çocuk görseli"
                width={792}
                height={430}
              />
              <p>
                Boğaz ağrısı şikayeti olan kişilerin atlamaması gereken bir
                diğer önemli nokta da günlük sıvı tüketimlerini artırmaktır.
                Ancak sıvı tüketimi artırılırken asitli ve boğazı tahriş
                edebilen sıvıların tüketiminden kaçınılmalı; bunu yerine su
                tercih edilmelidir. Yeterli miktarda sıvı tüketiminin
                yapılmaması halinde, boğaz bölgesinde doğal nemlendirici özellik
                gösteren tükürük ve mukus salgısı yeterli düzeyde
                üretilemeyebilir. Giderek kuruyan boğaz bölgesinde inflamasyonda
                kötüleşme meydana gelerek şişlik ve ağrı şikayetinin de
                ağırlaşmasına neden olabileceği için bilinçli olunmalıdır. Sıvı
                tercihi yapılırken suya ek olarak sıcak çay ve çorbaların
                tüketilmesi de uygundur. Ancak bu gıda ve içeceklerin aşırı
                sıcak olmamasına dikkat edilmelidir. Aşırı sıcak sıvıların
                tüketimi, hali hazırda tahriş olmuş boğaz dokusunun daha fazla
                zarar görmesine neden olarak, oluşan şikayetlerin de kötüleşmesi
                ile sonuçlanabilir. Aşırı sıcak çay ve çorba dışında alkol ve
                kafein içeren içecekler de boğaz bölgesinde kuruluğun artmasına
                neden olabileceği için kaçınılması gereken diğer sıvılar
                arasında yer alır.
              </p>
            </Level>
          </section>
          <section
            id="cocuklarda-bogaz-agrisina-ne-iyi-gelir"
            className={styles.kidsHolder}
          >
            <Level>
              <Level>
                <Heading>Çocuklarda Boğaz Ağrısına Ne İyi Gelir?</Heading>
                <p>
                  Çocuklarda soğuk algınlığı nedeniyle oluşan boğaz ağrısına
                  karşı hekimlerinin bilgisi dahilinde yapabileceğiniz çeşitli
                  uygulamalar bulunur. Çocuğun bulunduğu odanın nemlendirilmesi,
                  boğaz kuruluğu nedeniyle oluşan şikayetlerin azaltılmasına
                  katkı sağlayabilir. Burun tıkanıklığı şikayetinin eşlik etmesi
                  halinde nazal aspiratörler yardımıyla biriken mukusun
                  temizlenmesi çocukların daha rahat nefes alıp vermesine
                  yardımcı olabilir. Aynı zamanda soğuk algınlığı nedeniyle
                  oluşan boğaz ağrısı bulunan çocuklarda günlük sıvı tüketimine
                  de dikkat edilmelidir.
                </p>
                <Heading>
                  Bisolduo Şurup ve Bisolduo Action Sprey, öksürüğü rahatlatır
                  ve boğaz ağrısını yatıştırır.{" "}
                </Heading>
              </Level>
            </Level>
            <div className={classNames("flexbox", styles.productHolder)}>
              <StaticImage
                className={styles.image}
                src="../../../images/product-bar-bisolduo-surup.png"
                alt="Bisolnatur ürün görseli"
                width={282}
                height={282}
              />
              <div className={styles.content}>
                <p>
                  <b>Bisolduo şurup</b>, saf ve doğal bal içeriğine ek olarak
                  hatmi kökünün kuru ekstresini ihtiva eder. Bisolduo şurup ve
                  Bisolduo Action Sprey ilaç değildir, öksürüğün rahatlamasına
                  yardımcı olur. Bal, akışkanlığı artırıcı özelliği ile birlikte
                  kuru boğaz ağrısının dindirilmesine katkı sağlayabilen bir
                  doğal maddedir. Hatmi kökü (Althaea officinalis) ise Avrupa,
                  Doğu Asya ve Kuzey Afrika bölgelerine özgü bir bitkidir. Bu
                  bölgelerin geleneksel tıbbi uygulamalarında binlerce yıldır
                  kullanılan bu bitki, mukusa benzeri bir madde içererek boğaz
                  üzerini kaplar ve bu bölgenin yumuşamasına katkı sağlar.
                  Bisolduo şurup, boğaz bölgesinde tahriş olan mukozanın
                  yatışmasını ve tekrarlayıcı olarak ortaya çıkan kuru öksürük
                  şikayetinin yatıştırılmasına yardımcı olabilir. Hatmi kökünün
                  kaplayıcı etkisi, boğaz bölgesinde tahrişe neden olabilen dış
                  etkenlere karşı kişinin sağlığını korumasına katkı sağlar.
                </p>
              </div>
            </div>
            <div className={classNames("flexbox", styles.productHolder)}>
              <StaticImage
                className={styles.image}
                src="../../../images/product-bar-bisolduo-sprey.png"
                alt="Bisolnatur ürün görseli"
                width={282}
                height={282}
              />
              <div className={styles.content}>
                <p>
                  <b>
                    Bisolduo öksürük şurubuna ek olarak, boğaz ağrısına ve
                    öksürüğe ilk müdahalede kullanılabilecek Bisolduo Action
                    Sprey öksürüğü rahatlatır, boğaz ağrısını azaltır.
                  </b>{" "}
                  Bisolduo Action Sprey bal ve hatmi kökü ekstresine ek olarak
                  içerdiği Ectoin ile tahrişe uğramış boğaz mukozal dokusunun
                  üzerinde koruyucu film oluşturabilir; bu bölgenin
                  nemlendirilmesine katkıda bulunur ve mukozanın bariyer
                  fonksiyonunu destekleyici özellik göstererek sağlığın
                  korunmasına katkı sağlar. Bu etkileri ile Bisolduo Action
                  Sprey soğuk algınlığı kaynaklı olarak ortaya çıkan
                  şikayetlerin seyrinde lokal olarak hafifletici katkıda
                  bulunur.
                </p>
              </div>
            </div>
            <p>
              Gluten, laktoz, alkol, yapay aroma ve koruyucu içermeyen Bisolduo
              şurup, <b>bir tatlı kaşığı</b> vasıtası ile tüketilebilir.{" "}
              <b>
                2-6 yaş arasındaki çocuklarda günde en fazla 3 defa olacak
                şekilde yarım tatlı kaşığı (2,5 ml) olarak kullanılabilir. 6-12
                yaş arasındaki çocuklarda günde en fazla 3 defa olacak şekilde 1
                tatlı kaşığı (5 ml) olarak tüketilebilir; yetişkinler ve
                adolesanlarda günde en fazla 6 defa olacak şekilde 1 tatlı
                kaşığı (5 ml) olarak tüketilebilir.
              </b>{" "}
              Bisolduo Action Sprey’in kullanımı 2-5 yaş arasındaki çocuklar
              için günde 3 kez 1 defa püskürtülerek kullanılır. 6-11 yaş
              arasındaki çocuklarda ise önerilen kullanım şekli günde 3 defa 2
              kez püskürtme şeklindedir. Daha büyük çocuklar ve yetişkinler için
              Bisolduo Action Sprey günde birkaç kez 4 defa püskürtülerek
              kullanılabilir. Oldukça kullanışlı olan Bisolduo Action Sprey aynı
              zamanda taşıma açısından da kolay bir üründür.
              <br />
              <br />
              <b>
                Bisolduo Şurup ve Bisolduo Action Sprey, öksürüğü rahatlatmaya
                ve boğaz ağrısını yatıştırmaya yardımcı olur. Bisolduo Şurup
                aynı zamanda boğaz tahrişine neden olan dış faktörlere karşı
                mukozayı kaplar, veöksürme dürtüsünü dindirerek tahriş olmuş
                boğazınızı rahatlatmaya yardımcı olur.
              </b>
            </p>
          </section>
          <section className={styles.kaynakca}>
            <Level>
              <Level>
                <Heading id="kaynakca">Kaynakça</Heading>
              </Level>
            </Level>
            <p>
              1- https://www.mayoclinic.org/diseases-conditions/sore-throat/symptoms-causes/syc-20351635
            </p>
            <p>
              2- https://www.ncbi.nlm.nih.gov/books/NBK519550/
            </p>
            <p>
              3- https://www.healthline.com/health/sore-throat
            </p>
            <p>
              4- https://www.medicalnewstoday.com/articles/311449
            </p>
            <p>
              5-
              https://www.healthline.com/health/cold-flu/sore-throat-natural-remedies
            </p>
            <p>
              6- https://www.healthline.com/health/cold-flu/help-sore-throats
            </p>
            <p>
              7- https://www.verywellhealth.com/overview-of-sore-throat-1191991
            </p>
            <p>
              8-
              https://www.healthline.com/health/food-nutrition/marshmallow-root
            </p>
            <p>
              9- https://www.bisolnatur.com.tr/urunler/bisolduo-surup/
            </p>
            <p>
              10- https://www.bisolnatur.com.tr/urunler/bisolduo-sprey/
            </p>
            <p>
              11-
              https://www.whattoexpect.com/childrens-health-and-safety/sore-throats-in-children.aspx
            </p>
          </section>
        </div>
        <div className="date">
          <p>Yayınlama Tarihi: 2021-11-10</p>
          <p>Son güncellenme Tarihi: 2021-11-10</p>
        </div>
      </Container>
      <ProductBar />
    </Layout>
  );
};
export default CoughPage;
