// extracted by mini-css-extract-plugin
export var breadcrumb = "styles-module--breadcrumb--X5AbV";
export var breakme = "styles-module--breakme--jUHhv";
export var content = "styles-module--content--gqpvM";
export var header = "styles-module--header--6zeYl";
export var holder = "styles-module--holder--jeolF";
export var howHolder = "styles-module--howHolder--zE6rp";
export var image = "styles-module--image--8oGoW";
export var kaynakca = "styles-module--kaynakca--2mb17";
export var kidsHolder = "styles-module--kidsHolder--e3xTI";
export var listHolder = "styles-module--listHolder--LB-YO";
export var productHolder = "styles-module--productHolder--wkXaS";
export var rightCoughHolder = "styles-module--rightCoughHolder--pTSjU";
export var typesHolder = "styles-module--typesHolder--rrGA3";
export var whatHolder = "styles-module--whatHolder--A5Lk0";
export var whyHolder = "styles-module--whyHolder--Cdm+I";